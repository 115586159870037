import axios from 'axios';

const apiService = axios.create({
    baseURL: 'https://anmen.fdpbxiem.com',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': '0459f8ebb506c02718d4b29b11f586a0'
    }
});

const get = async (url, params) => {
    try {
        const response = await apiService.get(url, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

const post = async (url, data) => {
    try {
        const response = await apiService.post(url, data);
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        return null;
    }
};

export default { get, post };