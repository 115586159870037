
import styles from './index.module.scss';
import router from 'next/router';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import Image from 'next/image'
import profilePic from '~/public/img/xinxin_icon.png'
import IconLine from '~/public/img/icon_line.png'
import IconTg from '~/public/img/icon_tg.png'
import IconDiscord from '~/public/img/icon_discord.png'
import IconQQ from '~/public/img/icon_qq.png';
import { useState } from 'react';

const Footer: React.FC<any> = ({ changeLocale }) => {
    const intl = useIntl();
    const [isHover, setIsHover] = useState(false);
    const jupmList = [
        {
            img: "/img/footer3.png",
            link: "https://twitter.com/masobu_game"
        },
        {
            img: "/img/footer4.png",
            link: "https://www.instagram.com/masobu.ig/"
        },
        {
            img: "/img/footer6.png",
            link: "https://www.youtube.com/@MasobuGames"
        },
        {
            img: "/img/footer7.png",
            link: "https://www.facebook.com/people/Masobu-%E9%BA%BB%E6%B6%A9%E9%83%A8/61560487235220/"
        },
        {
            img: "/img/footer8.png",
            link: "https://discord.gg/xWhBxUYsDG"
        },
    ]
    return (
        <div className={styles.footerBox}>
            <div className={styles.footerPack}>
                <div className={styles.footerL}>
                    {/* <a href="https://vsde.lpio5tymk.com/home?dc=mgwt0005">
                        <img src="/img/footer1.png" alt="" className={styles.footerLogo} />
                    </a> */}

                </div>
                {/* <div className={styles.footerR}>
                    <div className={styles.footerTitlesPack}>
                        <div className={styles.footerTitlesTop}>
                            <img src="/img/footer2.png" alt="" />
                            <p className={styles.pc}> {intl.formatMessage({ id: "footer1" })}<br /> {intl.formatMessage({ id: "footer2" })}<br />            {intl.formatMessage({ id: "footer3" })}</p>
                            <p className={styles.h5}> {intl.formatMessage({ id: "footer1" })}<br />{intl.formatMessage({ id: "footer7" })}<br />{intl.formatMessage({ id: "footer8" })}<br />{intl.formatMessage({ id: "footer3" })}</p>
                        </div>
                        <h3 className={styles.footerText}>            {intl.formatMessage({ id: "footer4" })}</h3>
                    </div>
                </div> */}
            </div>
            <div className={styles.copyrightInfo}>
                <div className={styles.protocolPack}>
                    <Image src={profilePic} alt="" />
                    <div onClick={() => router.push('/privacyProtocol')} style={{ cursor: 'pointer' }}> {intl.formatMessage({ id: "footer5" })}</div>
                    <Image src={profilePic} alt="" />
                    <div onClick={() => router.push('/userProtocol')} style={{ cursor: 'pointer' }} > {intl.formatMessage({ id: "footer6" })}</div>
                    <Image src={profilePic} alt="" />
                </div>
                <p>All images, content & intellectual materials are copyright © 2024 Model Media US. All rights Reserved Eric Inc.</p>
                <ul>
                    <Link href="http://lin.ee/g0Of3BO" target="_blank">
                        <li><Image src={IconLine} alt="" /></li>
                    </Link>
                    <Link href="https://t.me/haiwang_game" target="_blank">
                        <li><Image src={IconTg} alt="" /></li>
                    </Link>
                    {/* <Link href="https://discord.gg/Psv6B7tr2R" target="_blank">
                        <li><Image src={IconDiscord} alt="" /></li>
                    </Link> */}
                    <li onClick={() => { setIsHover(!isHover) }}>
                        <Image src={IconQQ} alt="" />
                        {isHover && <div className={styles.hovPack}>
                            {/* <a href="https://qm.qq.com/q/Mwfu6iBn2K" aria-disabled target="_blank">
                                <div>海王传奇1</div>
                            </a> */}
                            {/* <a href="https://qm.qq.com/q/ZGTuBYgRKG" target="_blank">
                                <div>海王传奇2</div>
                            </a> */}
                            <a href="https://qm.qq.com/q/S3lpRywW8C" target="_blank">
                                <div>海王传奇3</div>
                            </a>
                            <a href="https://qm.qq.com/q/yQ6IsOtVxC" target="_blank">
                                <div>海王传奇4</div>
                            </a>
                            <a href="https://qm.qq.com/q/HSuy2Dng6i" target="_blank">
                                <div>海王传奇5</div>
                            </a>
                            <a href="https://qm.qq.com/q/YDlQI3n5ks" target="_blank">
                                <div>海王传奇6</div>
                            </a>
                        </div>}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;