
import Link from 'next/link';
import styles from './index.module.scss';
import router from 'next/router';
import { useState } from 'react';
const Story: React.FC = () => {
  const [showTagIndex, setShowTagIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    if (window.innerWidth <= 600) {
      if (showTagIndex === index) {
        setShowTagIndex(null); // 再次点击同一个时隐藏
      } else {
        setShowTagIndex(index); // 显示被点击的
      }

      // 如果是前三个元素，显示tag后延迟跳转
      if (index < 3) {

          router.push(`/rolePage?slide=${index}`);

      }
    } else if (index < 3) {
      // PC端直接跳转
      router.push(`/rolePage?slide=${index}`);
    }
  };

  return (
    <div className={styles.introductionPack}>
      <ul>
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <li 
            key={index} 
            onClick={() => handleItemClick(index - 1)}
            className={showTagIndex === (index - 1) ? styles.showTag : ''}
          >
            <img 
              src={`/img/figure${index}.png`} 
              alt="" 
            />
          <img 
            src={`/img/tag${index <= 3 ? '11' : '22'}.png`} 
            alt="" 
          />
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Story;