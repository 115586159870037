import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styles from './index.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, EffectCoverflow, Pagination, Navigation, Autoplay, } from 'swiper/modules';
import { Swiper } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const SwiperP: React.FC<any> = ({ changeLocale }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const intl = useIntl();
    const videoRef = useRef<HTMLVideoElement>(null);
    const videoRef1 = useRef<HTMLVideoElement>(null);
    const locale = intl.locale; // 获取当前语言环境
    const [screenWidth, setScreenWidth] = useState<number | null>(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth) {
                setScreenWidth(window.innerWidth);
            }
        };

        if (typeof window !== 'undefined') {
            setScreenWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);
    const miniDramaList = [{
        imgUrl: '/img/pcSwiper2.png',
        imgUrlCn: '/img/pcSwiper2Cn.png',
        imgUrlH5: '/img/pcSwiper2H5.png',
        imgUrlH5Cn: '/img/pcSwiper2H5Cn.png',
        shipin: '/img/shipin2.mp4',
        figure: '/img/swiperFigure2.png'
    }, {
        imgUrl: '/img/pcSwiper1.png',
        imgUrlCn: '/img/pcSwiper1Cn.png',
        imgUrlH5: '/img/pcSwiper1H5.png',
        imgUrlH5Cn: '/img/pcSwiper1H5Cn.png',
        shipin: '/img/shipin1.mp4',
        figure: '/img/swiperFigure1.png'
    }, {
        imgUrl: '/img/pcSwiper3.png',
        imgUrlCn: '/img/pcSwiper3Cn.png',
        imgUrlH5: '/img/pcSwiper3H5.png',
        imgUrlH5Cn: '/img/pcSwiper3H5Cn.png',
        shipin: '/img/shipin3.mp4',
        figure: '/img/swiperFigure3.png'
    }, {
        imgUrl: '/img/pcSwiper4.png',
        imgUrlCn: '/img/pcSwiper4Cn.png',
        imgUrlH5: '/img/pcSwiper4H5.png',
        imgUrlH5Cn: '/img/pcSwiper4H5Cn.png',
        shipin: '/img/shipin4.mp4',
        figure: '/img/swiperFigure4.png'

    }]
    const [ref, inView] = useInView({ // 目标元素在可视区域时 inView等于true
        threshold: 0.1,
    });
    const controls = useAnimation();
    const talkAbout = useAnimation();
    const playAnimation = async () => {
        controls.set({ opacity: 0, x: -80 });
        await controls.start({ opacity: 1, x: 0 });
    };
    const playAnimation2 = async () => {
        talkAbout.set({ opacity: 0 });
        await talkAbout.start({ opacity: 1 });
    };
    useEffect(() => {
        Swiper.use([EffectFade, EffectCoverflow, Pagination, Navigation, Autoplay]);
        swiperRef.current = new Swiper('.swiper-container', {
            effect : 'creative',
            centeredSlides: true, // 将滑块居中显示
            // loop: true,
            autoplay: {
                delay: 15000,
                disableOnInteraction: false
            },
            on: {
                slideChange: (swiper: { realIndex: SetStateAction<number>; }) => {
                    setActiveSlide(swiper.realIndex);
                    playAnimation();
                    playAnimation2();
                }
            }
        });
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.autoplay = true;
            videoRef.current.loop = true;
            videoRef.current.playsInline = true;
            videoRef.current.play();
        }
        if (videoRef1.current) {
            videoRef1.current.muted = true;
            videoRef1.current.autoplay = true;
            videoRef1.current.loop = true;
            videoRef1.current.playsInline = true;
            videoRef1.current.play();
        }
    }, []);

    const swiperRef = useRef<Swiper>(null!);
    const handlePrevSlide = () => {
        swiperRef.current.slidePrev();
    };
    const handleNextSlide = () => {
        swiperRef.current.slideNext();
    };

    
    return (
        <div className={styles.swiperPack}>
            <div className={styles.swiperSlideshow}>
                <div className={styles.swiperFrame}>
                    <div className={styles.swiperX + ' ' + "swiper-container"} >
                        <div className='swiper-wrapper' style={{ fontSize: '0' }}>
                            {miniDramaList?.map((item: any, index: any) => (<SwiperSlide key={index}>{locale}
                                <img src={locale == 'zh-CN' ? item.imgUrlCn : item.imgUrl} alt="" className={styles.swiperImgPc} />
                                <img src={locale == 'zh-CN' ? item.imgUrlH5Cn : item.imgUrlH5} alt="" className={styles.swiperImgH5} />
                            </SwiperSlide>))}
                        </div>
                        <motion.div
                            initial="hidden"
                            animate={(screenWidth! <= 600?talkAbout:controls)}
                            transition={{ duration: 2 }}
                            className={styles.videoPack}>
                            <video ref={videoRef1} className={styles.video} src={miniDramaList[activeSlide].shipin}></video>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={talkAbout}
                            transition={{ duration: 2 }}
                            className={styles.figurePack}>
                            <img src={miniDramaList[activeSlide].figure} alt="" />
                        </motion.div>
                    </div>
                </div>

                <div className={`${styles.swiperButtonPrevious} ${(activeSlide==0) ? styles.swiperButtonPreviousX : ''}`} onClick={() => { handlePrevSlide() }}></div>
                <div className={`${styles.swiperButtonNext} ${((miniDramaList.length-activeSlide)==1) ? styles.swiperButtonNextX : ''}`} onClick={() => { handleNextSlide() }}></div>
            </div>
        </div>
    )
}

export default SwiperP;