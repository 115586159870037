import styles from './index.module.scss';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import apiService from '@/hooks/apiService';
interface ChildComponentProps {
    setPopUpHeartTest: any;
}
const R18PopUp: React.FC<ChildComponentProps> = ({ setPopUpHeartTest }) => {
    const intl = useIntl();
    const locale = intl.locale;
    const multipleChoiceList = [{
        question: "Q1",
        topic: '如果你單身狀態下參加朋友的聚會\n當你走進酒吧時，第一個反應是什麼？',
        optionalAnswers: [{
            answer: "A",
            text: "趕緊環顧四周找到朋友並安靜地找個角落坐下。"
        }, {
            answer: "B",
            text: "觀察酒吧的裝潢和氣氛。"
        }, {
            answer: "C",
            text: "熟練地走到吧台跟調酒師點一杯喜歡的酒。"
        }],
        reply: ''
    }, {
        question: "Q2",
        topic: '與朋友在包廂聊了一陣子後\n你注意到吧台有位你感興趣的人，你會試著如何開啟話題？',
        optionalAnswers: [{
            answer: "A",
            text: "假裝不在意繼續與朋友聊天，時不時觀察吧台狀況。"
        }, {
            answer: "B",
            text: "找尋時機接近，展示你的幽默感，讓氣氛輕鬆愉快。"
        }, {
            answer: "C",
            text: "熟主動上前攀談，試著挑起對方的興趣和注意力，失敗了也無所謂。"
        }],
        reply: ''
    }, {
        question: "Q3",
        topic: '此時這位你感興趣的對象向你表示好感時，你的反應是？',
        optionalAnswers: [{
            answer: "A",
            text: "感到有點害羞，但試著保持禮貌態度，維持一個自在的距離。"
        }, {
            answer: "B",
            text: "欣然接受並試著進一步與對方有更多互動。"
        }, {
            answer: "C",
            text: "順著對方感興趣的話題詢問，引導對方不斷延伸話題。"
        }],
        reply: ''
    }, {
        question: "Q4",
        topic: '當與感興趣的對象聊了一段時間，此時調酒師前來詢問你需要點什麼飲料，你會在喜歡的人面前「特意」點什麼酒？',
        optionalAnswers: [{
            answer: "A",
            text: "大杯啤酒，看起來輕鬆隨意。"
        }, {
            answer: "B",
            text: "經典的威士忌，顯得成熟穩重。"
        }, {
            answer: "C",
            text: "調酒師特調，彰顯自己品味與個性。"
        }],
        reply: ''
    }, {
        question: "Q5",
        topic: '聚會進入尾聲，你走出酒吧門口的心情？',
        optionalAnswers: [{
            answer: "A",
            text: "社交能量用盡，想趕緊回家休息。"
        }, {
            answer: "B",
            text: "看好友有沒有要續攤，延續這個夜晚。"
        }, {
            answer: "C",
            text: "邀請喜歡的對象及好友去續攤。"
        }],
        reply: ''
    }]

    const [startTheTest, setStartTheTest] = useState(false);

    const [questionBank, setQuestionBankt] = useState<NewQuestionBankData[]>([multipleChoiceList[0]]);
    interface NewQuestionBankData {
        question: string;
        topic: string;
        optionalAnswers: { answer: string, text: string }[];
        reply: string;
    }

    const [email, setEmail] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [validEmail, setValidEmail] = useState(true);

    const handleEmailChange = (e: any) => {
        const email = e.target.value;
        setEmailValue(email);
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    };
    const handleSendEmail = async () => {
        if (!validEmail|| email) {return}
        const params = {
            email: emailValue,
            exames: answerSheet
        }
        const result = await apiService.post('/api/app/user/psy/submit', params);
        if (result == 'ok') {
            setEmail(emailValue)
        } else {
            console.log(result)
        }
    };

    const [answerSheet, setAnswerSheet] = useState<NewData[]>([]); //答题卡列表
    interface NewData {
        question: any;
        answer: any;
    }

    const selectionMethod = (data: any, item2: any, index: any) => { //选择答题逻辑
        if (data.reply) { return } //判断当前问题是否已回答
        const newData = {
            question: data.question,
            answer: item2.answer
        };
        setAnswerSheet(prevState => [...prevState, newData]);
        const updatedArray = questionBank.map((item, index) => {
            if (index === (questionBank.length - 1)) {
                return { ...item, reply: item2.text };
            }
            return item;
        });
        if (multipleChoiceList.length !== updatedArray.length) { //判断是否最后一题
            updatedArray.push(multipleChoiceList[questionBank.length])
        }
        setQuestionBankt(updatedArray)
    };

    const resetTest = () => { //重置 重新测验
        setQuestionBankt([multipleChoiceList[0]])
        setAnswerSheet([])
        setEmailValue('')
        setEmail('')
    };

    const confirm = () => {
        setPopUpHeartTest(false)
    };
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => { //监听将滚动条 滚动到底部
        scrollToBottom();
    }, [questionBank, email]);
    return (
        <div className={styles.Pack}>
            <div className={styles.popUps}>
                <div className={styles.closurePack}>
                    <Button color="default" variant="text" icon={<CloseOutlined style={{ color: '#A647EA', fontSize: '20px' }} />} onClick={() => confirm()} />
                </div>
                <div className={styles.popUpsHeart}>
                    <img src={locale == 'zh-CN' ? "/img/footerLoginCn.png": "/img/footerLogin.png"} alt="" className={styles.logoImg}/>
                    {
                        !startTheTest ? <div className={styles.test1Pack}>
                            
                            <div className={styles.text}>
                                <p>測驗計分方式：</p>
                                <p>每選擇一個 A 得 1分，B 得 2分，C 得 3分。</p>
                                <p>將五個問題的分數相加，得到總分。</p>
                            </div>
                            <Button color="primary" variant="outlined" shape="round" size="large" style={{ borderColor: '#A647EA', color: '#A647EA' }} block onClick={() => { setStartTheTest(true) }}>開始測驗</Button>
                        </div> :
                            <div className={styles.test2Pack}>
                                {questionBank?.map((item: any, index: any) => (
                                    <div className={styles.topicPack} key={index}>
                                        <div className={styles.title}>
                                            <img src="/img/decorate-image.png" alt="" />
                                            <em>问题{++index}/5</em>
                                            <img src="/img/decorate-image.png" alt="" style={{ transform: 'rotateY(180deg)' }} />
                                        </div>
                                        <div className={styles.topicPack}>
                                            <div className={styles.topic}>{item.topic}</div>
                                        </div>
                                        <ul>
                                            {item.optionalAnswers?.map((item2: any, index2: any) => (
                                                <li key={index2} onClick={() => { selectionMethod(item, item2, index) }}>
                                                    <div className={styles.sequence}>{item2.answer}</div>
                                                    {item2.text}
                                                </li>
                                            ))}
                                        </ul>
                                        {item?.reply && <div className={styles.answerPack}>
                                            <div className={styles.answer}>{item?.reply}</div>
                                        </div>}
                                    </div>
                                ))}
                                {(questionBank.length === multipleChoiceList.length && questionBank[multipleChoiceList.length - 1].reply) &&
                                    <div className={styles.topicPack}>
                                        <div className={styles.title}>
                                            <img src="/img/decorate-image.png" alt="" />
                                            <em>測驗結束</em>
                                            <img src="/img/decorate-image.png" alt="" style={{ transform: 'rotateY(180deg)' }} />
                                        </div>
                                        <div className={styles.topicPack}>
                                            <div className={styles.topic}>輸入你的個人電子信箱</div>
                                        </div>
                                        <div className={styles.inputPack}>
                                            <Input
                                                placeholder="請輸入電子信箱"
                                                onChange={handleEmailChange}
                                                style={{
                                                    textAlign: 'center',
                                                    border: '2px solid #DAB0FF',
                                                    borderRadius: '40px',
                                                    height: '48px',
                                                    borderColor: validEmail ? '#DAB0FF' : 'red'
                                                }} />
                                            <div className={styles.buttonPack}>
                                                {validEmail && <Button
                                                    color="default"
                                                    variant="text"
                                                    icon={<ArrowRightOutlined style={{ color: '#A647EA', fontSize: '20px' }} />}
                                                    onClick={() => handleSendEmail()} />}
                                            </div>
                                        </div>
                                        {email && <div className={styles.answerPack}>
                                            <div className={styles.answer}>{email}</div>
                                        </div>}
                                        {email && <div className={styles.topicPack}>
                                            <div className={styles.topic}>測驗結果已發送至你填寫的電子信箱咯～</div>
                                            <Button color="primary" variant="outlined" shape="round" size="large" style={{ marginTop: '20px', borderColor: '#A647EA', color: '#A647EA' }} block onClick={() => { resetTest() }}>重新測驗</Button>
                                        </div>}
                                    </div>}
                                <div ref={messagesEndRef} />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default R18PopUp;